import algoliasearch from 'algoliasearch/lite'

const saveRecentSearch = (query) => {
  // get recent searches or instantiate an empty array
  const recentSearches = JSON.parse(
    localStorage.getItem('AUTOCOMPLETE_RECENT_SEARCHES:RECENT_SEARCH') || '[]'
  )

  // if query is already saved, stop execution at this point
  if (recentSearches.find((item) => item.id === query) || null) return

  // add search query to recent searches
  localStorage.setItem(
    'AUTOCOMPLETE_RECENT_SEARCHES:RECENT_SEARCH',
    JSON.stringify([
      ...recentSearches,
      {
        id: query,
        label: query,
      },
    ])
  )
}

const appId = import.meta.env.VITE_ALGOLIA_APP_ID
const apiKey = import.meta.env.VITE_ALGOLIA_SEARCH_KEY

const algoliaSearchClient = algoliasearch(appId, apiKey)

export { saveRecentSearch, appId, apiKey, algoliaSearchClient }
